<script>
import { Container, Header, Main, Row, Col, Table, TableColumn, Button, Checkbox, Select, Option, Divider, RadioGroup, RadioButton, DatePicker, Message,Tooltip,CheckboxGroup,Input } from 'element-ui'
import { Mixin } from '@/mixins/util.js'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
export default {
  name: 'inventoryInfo',
  mixins: [Mixin],
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-container': Container,
    'el-header': Header,
    'el-main': Main,
    'el-row': Row,
    'el-col': Col,
    'el-checkbox': Checkbox,
    'el-checkbox-group': CheckboxGroup,
    'el-select': Select,
    'el-option': Option,
    'el-divider': Divider,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-date-picker': DatePicker,
    'el-tooltip': Tooltip,
    'el-input': Input,
  },
  data(){
    return {
      inventoryData:null,
      product_type:'1',
      selected_date:null,
      height:'75vh',
      pickerOptions: {
          // disabledDate(time) {
          //   return time.getTime() > Date.now();
          // },
      },
      showFilterArea:true,
      sales_type1:true,
      sales_type2:true,
      sales_type3:true,
      pn_selected:'',
      pc_selected:'',
      pt_selected:'',
      mall_options:[],
      retail_options:[],
      record_form_table:{
        '005_000002_001_00000000':'LC',
        '005_000002_002_00000000':'LC',
        '005_000033_001_00000000':'MOMO',
        '005_000035_001_00000000':'YHOO',
        '005_000036_001_00000000':'SHOPEE',
        '005_000038_001_00000000':'SHOPLINE',
        '005_000039_001_00000000':'SHOPIFY',
        '005_000041_001_00000000':'PCHOME',
        '005_000043_001_00000000':'ETMALL',
        '005_000045_001_00000000':'BOOKS',
        '005_000048_001_00000000':'FRIDAY',
      },
      pt_options:[
        { label:'単品', value:'2'},
        { label:'販促', value:'3'},
        { label:'同捆物', value:'4'},
        { label:'資材', value:'5'},
      ],
      stateList:[],
      curPage: 1,
      pageSize: 50,
      pagerCount: 11,
      page_size_options:[50,100,150],
    }
  },
  watch:{
    product_type(newVal) {
      if (newVal==='1') this.pt_selected = []
    }
  },
  computed:{
    excludeList(){
      return this.stateList.filter(item=>{
        return item.product_state===false
      })
      .map(d=>d.product_code)
    },
    cols(){
      const cols = [
        {key:'product_code',label:'品番',width:80,align:'center'},
        {key:'product_name',label:'商品名',width:250,align:'center'},
        
        {key:'eopm',label:'前月末在庫数',align:'right',formatter:this.numFormatter},
        {key:'in_actually',label:'入荷数',align:'right',formatter:this.numFormatter},
        {key:'input_broken',label:'輸入時破損数',align:'right',formatter:this.numFormatter},
        {key:'out_theoretically',label:'理論出荷数',align:'right',formatter:this.numFormatter},
        {key:'out_actually',label:'出荷数',align:'right',formatter:this.numFormatter},
        {key:'reject_theoretically',label:'理論拒収数',align:'right',formatter:this.numFormatter},
        {key:'reject_actually',label:'拒収数',align:'right',formatter:this.numFormatter},
        {key:'reject_bad',label:'受取拒否不良品',align:'right',formatter:this.numFormatter},
        {key:'return_theoretically',label:'理論返品数',align:'right',formatter:this.numFormatter},
        {key:'return_good_actually',label:'返品数(良品)',align:'right',formatter:this.numFormatter},
        {key:'return_bad_actually',label:'返品数(不良品)',align:'right',formatter:this.numFormatter},
        {key:'dip',label:'良品在庫移動',align:'right',formatter:this.numFormatter},
        {key:'eomt',label:'月末理論在庫数',align:'right',formatter:this.numFormatter},
        {key:'inventory',label:'棚卸し在庫数',align:'right',formatter:this.numFormatter},
      ]
      if(this.product_type === '2' && this.sales_type3 === false)
        cols.splice(2, 0, {key:'prediction',label:'出荷予測週数',width:90,align:'center',formatter:this.predictFormatter})
      return cols
    },
    yusen_data(){
      if(!this.inventoryData) return []
      if(!this.sales_type1) return []
      const data = this.inventoryData.union_yusen_pelican
      .map(d=>{
        //end of month theoretically
        //(前月 + 入荷 + 理拒 + 返良) - (輸破 + 理出 + 良移 + 受取拒否不良品) 
        const eomt = d[2] + d[3] + d[7] + d[11] - d[4] - d[5] - d[15] - d[9]
        //良品在庫移動
        const dip = d[15] === '' ? 0 :d[15]*1
        return {
          product_code:this.Mosaic(d[0],sessionStorage.getItem('session_id')),
          product_name:this.Mosaic(d[1],sessionStorage.getItem('session_id')),
          eopm:d[2],//end of previous month 
          in_actually:d[3],
          input_broken:d[4],
          out_theoretically:d[5],
          out_actually:d[6],
          reject_theoretically:d[7],
          reject_actually:d[8],
          reject_bad:d[9],
          return_theoretically:d[10],
          return_good_actually:d[11],
          return_bad_actually:d[12],
          inventory:d[13],
          record_form:d[14],
          total_shipped:d[16],
          product_type:d[17],
          dip,
          eomt,
          prediction:0
        }
      })
      .map(d=>{
          d.record_form_source = this.record_form_table[d.record_form]
          return d
      })
      .filter(d=>{
          return this.excludeList.includes(d.product_code) === false
        })
      return data
    },
    mall_data(){
      if(!this.inventoryData) return []
      if(!this.sales_type2) return []
      const d = this.inventoryData.mall
      .map(d=>{
        //end of month theoretically
        //(前月 + 入荷 + 理拒 + 返良) - (輸破 + 理出 + 良移 + 受取拒否不良品) 
        const eomt = d[2] + d[3] + d[7] + d[11] - d[4] - d[5] - d[15]- d[9]
        //良品在庫移動
        const dip = d[15] === '' ? 0 :d[15]*1
        return {
          product_code:this.Mosaic(d[0],sessionStorage.getItem('session_id')),
          product_name:this.Mosaic(d[1],sessionStorage.getItem('session_id')),
          eopm:d[2],//end of previous month 
          in_actually:d[3],
          input_broken:d[4],
          out_theoretically:d[5],
          out_actually:d[6],
          reject_theoretically:d[7],
          reject_actually:d[8],
          reject_bad:d[9],
          return_theoretically:d[10],
          return_good_actually:d[11],
          return_bad_actually:d[12],
          inventory:d[13],
          record_form:d[14],
          total_shipped:d[16],
          product_type:d[17],
          dip,
          eomt,
          prediction:0
        }
      })
      .map(d=>{
          d.record_form_source = this.record_form_table[d.record_form]
          return d
      })
      .filter(d=>{
          return this.excludeList.includes(d.product_code) === false
      })
      return d
    },
    retail_data(){
      if(!this.inventoryData) return []
      if(!this.sales_type3) return []
      const d = this.inventoryData.retail.map(d=>{
        //end of month theoretically
        //月末理論在庫數 = 前月末理論在庫 + 入荷數 - 理論出荷數 - 良品在庫移動 - 理論返品
        const eomt = d[2] + d[3] - d[5] - d[15] - d[10]
        //良品在庫移動
        const dip = d[15] === '' ? 0 :d[15]*1
        return {
          product_code:this.Mosaic(d[0],sessionStorage.getItem('session_id')),
          product_name:this.Mosaic(d[1],sessionStorage.getItem('session_id')),
          eopm:d[2],//end of previous month 
          in_actually:d[3],
          input_broken:d[4],
          out_theoretically:d[5],
          out_actually:d[6],
          reject_theoretically:d[7],
          reject_actually:d[8],
          reject_bad:d[9],
          return_theoretically:d[10],
          return_good_actually:d[11],
          return_bad_actually:d[12],
          inventory:d[13],
          record_form:d[14],
          total_shipped:d[16],
          product_type:d[17],
          dip,
          eomt,
          prediction:0
        }
      })
      .map(d=>{
          const source = d.record_form.split('_')
          d.record_form_source = source[source.length-1]
          return d
      })
      return d
    },
    filter_mall_data(){
      const options = this.mall_options.reduce((acc,cur)=>{
        if(cur.checked) acc.push(cur.label)
        return acc
      },[])
      return this.mall_data.filter(d=>options.includes(d.record_form_source))
    },
    filter_retail_data(){
      const options = this.retail_options.reduce((acc,cur)=>{
        if(cur.checked) acc.push(cur.label)
        return acc
      },[])
      return this.retail_data.filter(d=>options.includes(d.record_form_source))
    },
    union_data(){
      const d1 = this.yusen_data.map(d=>{
        return {
          product_code:d.product_code,
          product_name:d.product_name,
          eopm:d.eopm,//end of previous month 
          in_actually:d.in_actually,
          input_broken:d.input_broken,
          out_theoretically:d.out_theoretically,
          out_actually:d.out_actually,
          reject_theoretically:d.reject_theoretically,
          reject_actually:d.reject_actually,
          reject_bad:d.reject_bad,
          return_theoretically:d.return_theoretically,
          return_good_actually:d.return_good_actually,
          return_bad_actually:d.return_bad_actually,
          inventory:d.inventory,
          record_form:d.record_form,
          prediction:d.prediction,
          total_shipped:d.total_shipped,
          product_type:d.product_type,
          dip:d.dip,
          eomt:d.eomt
        }
      })
      const d2 = this.filter_mall_data.map(d=>{
        return {
          product_code:d.product_code,
          product_name:d.product_name,
          eopm:d.eopm,//end of previous month 
          in_actually:d.in_actually,
          input_broken:d.input_broken,
          out_theoretically:d.out_theoretically,
          out_actually:d.out_actually,
          reject_theoretically:d.reject_theoretically,
          reject_actually:d.reject_actually,
          reject_bad:d.reject_bad,
          return_theoretically:d.return_theoretically,
          return_good_actually:d.return_good_actually,
          return_bad_actually:d.return_bad_actually,
          inventory:d.inventory,
          record_form:d.record_form,
          prediction:d.prediction,
          total_shipped:d.total_shipped,
          product_type:d.product_type,
          dip:d.dip,
          eomt:d.eomt
        }
      })
      const d3 = this.filter_retail_data.map(d=>{
        return {
          product_code:d.product_code,
          product_name:d.product_name,
          eopm:d.eopm,//end of previous month 
          in_actually:d.in_actually,
          input_broken:d.input_broken,
          out_theoretically:d.out_theoretically,
          out_actually:d.out_actually,
          reject_theoretically:d.reject_theoretically,
          reject_actually:d.reject_actually,
          reject_bad:d.reject_bad,
          return_theoretically:d.return_theoretically,
          return_good_actually:d.return_good_actually,
          return_bad_actually:d.return_bad_actually,
          inventory:d.inventory,
          record_form:d.record_form,
          prediction:d.prediction,
          total_shipped:d.total_shipped,
          product_type:d.product_type,
          dip:d.dip,
          eomt:d.eomt
        }
      })
      const d =  [...d1,...d2,...d3]
      return d.reduce((a,c)=>{
          if(a.find(d=>d.product_code===c.product_code)===undefined){
            a.push(c)
          }else{
            const index = a.findIndex(d=>d.product_code===c.product_code)
            a[index].eopm +=c.eopm
            a[index].in_actually +=c.in_actually
            a[index].input_broken +=c.input_broken
            a[index].out_theoretically+=c.out_theoretically
            a[index].reject_theoretically +=c.reject_theoretically
            a[index].out_actually +=c.out_actually
            a[index].reject_theoretically +=c.reject_theoretically
            a[index].reject_actually +=c.reject_actually
            a[index].reject_bad +=c.reject_bad
            a[index].return_theoretically +=c.return_theoretically
            a[index].return_good_actually +=c.return_good_actually
            a[index].return_bad_actually +=c.return_bad_actually
            a[index].inventory +=c.inventory
            a[index].dip +=c.dip
            a[index].eomt +=c.eomt
            a[index].total_shipped +=c.total_shipped
          }
          return a
        },[])
        .map(d=>{
          if(d.total_shipped!==0) d.prediction = Math.round(d.inventory/d.total_shipped/4)
          return d
        })
    },
    union_data_filter_pnpc(){
      return this.union_data
      .filter(d=>{
        if(this.pn_selected.length === 0) return d
        return this.pn_selected.includes(d.product_name)
      })
      .filter(d=>{
        if(this.pc_selected.length === 0) return d
        return this.pc_selected.includes(d.product_code)
      })
      .filter(d=>{
        if((this.pt_selected.length === 0 && this.product_type==='1') || 
        this.pt_selected.length === 0) return d
        return this.pt_selected.includes(d.product_type)
      })
    },
    union_data_with_pager(){
      return this.union_data_filter_pnpc
        .slice(
          this.pageSize * (this.curPage - 1),
          this.pageSize * this.curPage
        )
    },
    pn_options(){
      return [...this.yusen_data,...this.filter_mall_data,...this.retail_data]
        .filter(d=>d.product_name !== '')
        .reduce((acc,cur)=>{
          if(!acc.find(option=>option === cur.product_name)){
            acc.push(cur.product_name)
          }
          return acc
        },[])
    },
    pc_options(){
      return [...this.yusen_data,...this.filter_mall_data,...this.retail_data]
        .filter(d=>d.product_code !== '')
        .reduce((acc,cur)=>{
          if(!acc.find(option=>option === cur.product_code)){
            acc.push(cur.product_code)
          }
          return acc
        },[])
    }
  },
  methods:{
    async getInventoryData(){
      if(this.selected_date === null) {
        Message.error('Please select date')
        return
      }

      const s_id = sessionStorage.getItem('session_id')
      const url = `https://www.bamb.asia/suppliers/inventoryInfo`
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "text/plain;charset=UTF-8",
        },
        body: `sessionid=${s_id}&product_type=${this.product_type}&selected_date=${this.selected_date}`,
      };
      let long_arr = [],short_arr = []
      await fetch(url, config)
        .then((res) => res.text())
        .then((rep) => {
          rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
          if (rep.errno === "1") {
            this.inventoryData = rep.records
            //////////// 合併yusen&pelican ///////////////
            if(this.inventoryData.yusen.length>=this.inventoryData.pelican.length){
              long_arr = this.inventoryData.yusen.map(d=>[...d])
              short_arr = this.inventoryData.pelican.map(d=>[...d])
            }else{
              long_arr = this.inventoryData.pelican.map(d=>[...d])
              short_arr = this.inventoryData.yusen.map(d=>[...d])
            }
            this.inventoryData.union_yusen_pelican = long_arr
            for (const short_item of short_arr) {
              const index = this.inventoryData.union_yusen_pelican.findIndex(d=>d[0]===short_item[0])
              if(index!==-1){
                for (let idx = 0; idx < short_item.length; idx++) {
                  if(idx!==0 && idx!==1 && idx!==14 && idx!==17)
                  this.inventoryData.union_yusen_pelican[index][idx] += short_item[idx]
                }
              }else{
                this.inventoryData.union_yusen_pelican.push(short_item)
              }
            }
            this.mall_options = this.mall_data.reduce((acc,cur)=>{
              if(!acc.find(option=>option.label === this.record_form_table[cur.record_form])){
                acc.push({label:this.record_form_table[cur.record_form],checked:true})
              }
              return acc
            },[])
            //005_000205_000_
            this.retail_options = this.retail_data.reduce((acc,cur)=>{
              if(!acc.find(option=>option.label === cur.record_form_source)){
                acc.push({label:cur.record_form_source,checked:true})
              }
              return acc
            },[])
          }
        })
    },
    numFormatter(row, column){
      return row[column.property].toLocaleString()
    },
    predictFormatter(row, column,cell){
      if(!isNaN(cell) && Math.round(cell)<10)
        return this.$createElement('span',{class:'warn-text'},Math.round(cell).toLocaleString())
      else return cell
    },
    exportExcelHandler(){
      if(this.union_data_filter_pnpc.length===0) return 
      const workbook = new Excel.Workbook()
      const worksheet = workbook.addWorksheet()
      worksheet.columns = this.cols.map(col=>{
        return {header: col.label, key: col.key, width: 15}
      })
      worksheet.addRows(this.union_data_filter_pnpc)

      workbook.xlsx.writeBuffer().then(data => {
        let blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        saveAs(blob, `在庫.xlsx`)
      })
    },
    getSetState(args) {
      if(this.stateList.length ===0)
        this.stateList = args //init for first time
      else{
        //modify single set
        const index = this.stateList.findIndex(item=>item.product_code === args.product_code)
        this.$set(this.stateList,index,args)
      }
    }
  },
  mounted(){
    this.$eventBus.$on('getSetState', this.getSetState, this)
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
